import validate from "/home/lessmess/domains/stage.lessmess.io/www/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import client_45auth from "/home/lessmess/domains/stage.lessmess.io/www/frontend/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/client-auth.mjs";
import manifest_45route_45rule from "/home/lessmess/domains/stage.lessmess.io/www/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  client_45auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  menu: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/middleware/menu.js"),
  auth: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/auth.mjs"),
  "guest-only": () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/guest-only.mjs")
}