import { default as indexWar9MygbtdMeta } from "/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexT6g6HDl9ZrMeta } from "/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/board/index.vue?macro=true";
import { default as gtmroaYhMSHfCMeta } from "/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/gtm.vue?macro=true";
import { default as onboardingO36pmFNyUuMeta } from "/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/mail/onboarding.vue?macro=true";
import { default as indexdv9o6KkZKdMeta } from "/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/start/index.vue?macro=true";
import { default as indexSxO936rNloMeta } from "/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/user/login/index.vue?macro=true";
import { default as indexPFSu9oiSKqMeta } from "/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/user/register/index.vue?macro=true";
import { default as onboardingxgZEJsxsD9Meta } from "~/pages/mail/onboarding.vue?macro=true";
export default [
  {
    name: indexWar9MygbtdMeta?.name ?? "slug",
    path: indexWar9MygbtdMeta?.path ?? "/:slug(.*)*",
    meta: indexWar9MygbtdMeta || {},
    alias: indexWar9MygbtdMeta?.alias || [],
    redirect: indexWar9MygbtdMeta?.redirect || undefined,
    component: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexT6g6HDl9ZrMeta?.name ?? "board",
    path: indexT6g6HDl9ZrMeta?.path ?? "/board",
    meta: indexT6g6HDl9ZrMeta || {},
    alias: indexT6g6HDl9ZrMeta?.alias || [],
    redirect: indexT6g6HDl9ZrMeta?.redirect || undefined,
    component: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/board/index.vue").then(m => m.default || m)
  },
  {
    name: gtmroaYhMSHfCMeta?.name ?? "gtm",
    path: gtmroaYhMSHfCMeta?.path ?? "/gtm",
    meta: gtmroaYhMSHfCMeta || {},
    alias: gtmroaYhMSHfCMeta?.alias || [],
    redirect: gtmroaYhMSHfCMeta?.redirect || undefined,
    component: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/gtm.vue").then(m => m.default || m)
  },
  {
    name: onboardingO36pmFNyUuMeta?.name ?? "mail-onboarding",
    path: onboardingO36pmFNyUuMeta?.path ?? "/mail/onboarding",
    meta: onboardingO36pmFNyUuMeta || {},
    alias: onboardingO36pmFNyUuMeta?.alias || [],
    redirect: onboardingO36pmFNyUuMeta?.redirect || undefined,
    component: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/mail/onboarding.vue").then(m => m.default || m)
  },
  {
    name: indexdv9o6KkZKdMeta?.name ?? "start",
    path: indexdv9o6KkZKdMeta?.path ?? "/start",
    meta: indexdv9o6KkZKdMeta || {},
    alias: indexdv9o6KkZKdMeta?.alias || [],
    redirect: indexdv9o6KkZKdMeta?.redirect || undefined,
    component: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/start/index.vue").then(m => m.default || m)
  },
  {
    name: indexSxO936rNloMeta?.name ?? "user-login",
    path: indexSxO936rNloMeta?.path ?? "/user/login",
    meta: indexSxO936rNloMeta || {},
    alias: indexSxO936rNloMeta?.alias || [],
    redirect: indexSxO936rNloMeta?.redirect || undefined,
    component: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/user/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexPFSu9oiSKqMeta?.name ?? "user-register",
    path: indexPFSu9oiSKqMeta?.path ?? "/user/register",
    meta: indexPFSu9oiSKqMeta || {},
    alias: indexPFSu9oiSKqMeta?.alias || [],
    redirect: indexPFSu9oiSKqMeta?.redirect || undefined,
    component: () => import("/home/lessmess/domains/stage.lessmess.io/www/frontend/pages/user/register/index.vue").then(m => m.default || m)
  },
  {
    name: onboardingxgZEJsxsD9Meta?.name ?? "mail-onboarding",
    path: onboardingxgZEJsxsD9Meta?.path ?? "/mail/:type/:smirchId/:rayonId/:uid/:token",
    props: true,
    meta: onboardingxgZEJsxsD9Meta || {},
    alias: onboardingxgZEJsxsD9Meta?.alias || [],
    redirect: onboardingxgZEJsxsD9Meta?.redirect || undefined,
    component: () => import("~/pages/mail/onboarding.vue").then(m => m.default || m)
  }
]